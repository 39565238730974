import React from 'react';

import PageWrap from 'components/PageWrap';
import { Section } from 'components/Section';
import { PagePicTitle } from 'components/PagePicTitle';

import style from './learn.module.scss';

import { ModuleIcon } from 'components/ModuleIcon';
import { SEO } from 'components/Helmets';
import IndyLink from 'components/IndyLink';

export default function LearnPage(){
    const pagePath = "/learn";

    return(
        <PageWrap noSEO={true} path={pagePath}>
            <SEO
                title="Learn Mashoom"
                description="Learn about Mashoom's features and how to use them"
                path={pagePath}
            />
           <PagePicTitle>
                <h1>Learn Mashoom</h1>
                <p>We design our software to be as intuitive as possible, but sometimes it's good to read up on them. Here you will find tutorials on every aspect of our modules.</p>
            </PagePicTitle>
            <Section>
                <h1 id={style.selectTopicTitle}>Select a topic to learn about</h1>
                <div className={style.learnTopics}>
                    <TopicIcon iconName="Mashoom" title="General" adr="/learn/general" />
                    <TopicIcon iconName="Design" title="Design" adr="/learn/design" />
                    <TopicIcon iconName="Library" title="Library" adr="/learn/library" />
                    <TopicIcon iconName="Share" title="Share" adr="/learn/share" />
                </div>
                <p id={style.moreTopics}>We are constantly adding and updating these tutorials. If you feel something is missing or incorrect, please <IndyLink adr="/about/#ContactUs">get in contact</IndyLink>.</p>
            </Section>
        </PageWrap>
    );
}

function TopicIcon({iconName, title, adr}){
    return (
        <IndyLink adr={adr}>
            <div className={style.learnTopic}>
                <ModuleIcon name={iconName} containerClass={style.ModuleIcon} />
                <h2>{title}</h2>
            </div>
        </IndyLink>
    );
}